<template>
  <div class="about" v-loading="loading">......</div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("logout");

      if (this.$route.query.redirect) {
        window.location = `/callback?redirect=${this.$route.query.redirect}`;
      } else {
        window.location = `/callback`;
      }
    },
  },
  created() {
    this.handleLogin();
  },
};
</script>
